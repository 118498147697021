@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities{
    html {
        @apply scroll-smooth;
    }
}

